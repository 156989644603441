body {
  min-height: 100vh;
  background-color: black;
}

.App {
  min-height: 100%;
  color: white;
}

.nav {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.3rem 0;
  border-bottom: 1px solid grey;
  user-select: none;
}

.header-button {
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.1rem;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

#logo {
  height: 70px;
  width: 170px;
  background-size: 170px 70px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
  line-height: 2rem;
}

#dashboard {
  min-height: calc(100vh - 100px);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  user-select: none;
}

#player {
  margin: 1% 0;
  padding: 1% 0;
  border-radius: 5px;
  width: 100%;
}

#player-buttons-container {
  display: grid;
  grid-template-columns: 85px 90px 85px;
  align-items: center;
  justify-content: center;
  grid-gap: 3px;
}

#player-play-button {
  margin: auto;
  height: 54px;
  width: 54px;
  background-size: 54px 54px;
  border-radius: 50%;
  background-repeat: no-repeat;
  border: 3px solid white;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-icon {
  margin: auto;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  background-size: 32px 32px;
}

.player-icon:active {
  transform: scale(0.9);
  transition-duration: 0.15s;
}

.player-icon.chevron-icon:active {
  transform: scale(0.9) rotate(45deg) translate(-4px, 4px);
  transition-duration: 0.15s;
}

.add-time-icon {
  border: 1.2px solid white;
  height: 32px;
  width: 55px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chevron-container {
  height: 32px;
  width: 55px;
  margin: auto;
}

.chevron-icon {
  height: 22px;
  width: 22px;
  border-top: 5px solid white;
  border-right: 5px solid white;
  border-radius: 15%;
  transform: rotate(45deg) translate(-4px, 4px);
}

.player-time {
  padding: 0 1%;
}

#player-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bounce {
  animation: bounce 0.4s infinite alternate;
}

@keyframes bounce {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
}

.info-text {
  font-size: 2vh;
}

#guess-boxes {
  margin: 1% 0;
  padding: 0;
  width: 100%;
}

.guess-box {
  height: 5vh;
  margin: 0.5rem;
  border: 1px solid grey;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.guess-box.skip {
  color: white;
}

#album-cover {
  margin: 5px;
  height: 230px;
  width: 230px;
  background-size: 230px 230px;
  background-position: center;
  background-repeat: no-repeat;
}

.progress {
  height: 12px;
  width: 100%;
}

.progress-bar.no-transition {
  transition-duration: none;
}

.bg-custom {
  background-color: rgb(153, 153, 153);
}

.bg-blocker {
  background-color: rgb(83, 83, 83);

  outline: 0.5px solid white;
  outline-offset: -1px;
}

.modal-content {
  background-color: rgb(15, 15, 15);
  color: white;
  border: 0.1rem solid rgb(31, 31, 31);
}

.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
}

.dropdown-menu {
  overflow-y: auto;
  max-height: 30vh;
}

.result-symbols {
  width: 24px;
  height: 24px;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.guess-box-icon {
  margin-right: 2%;
  height: 10px;
  width: 10px;
  background-size: 10px 10px;
}

#scorebar-container {
  max-height: 500px;
  max-width: 500px;
}

.scorebar {
  margin-left: 1%;
  height: 20px;
  border: 1px solid rgb(56, 56, 56);
}

#continue-guest-text {
  font-size: 1.2rem;
  color: rgb(199, 199, 199);
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

#continue-guest-text:hover {
  color: rgb(160, 160, 160);
}

#upload-label {
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 42, 44);
  user-select: none;
  transition: 0.2s;
}

#upload-label:hover {
  background-color: rgb(33, 37, 41);
}

#upload-label:active {
  box-shadow: 0 0 0 4px rgb(40, 42, 44);
}
